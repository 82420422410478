<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ user.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_USERS)"
            @click="deleteUser"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
            @click="editUser"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_EDIT_USERS) && user.active == 0
            "
            @click="activateUser"
          >
            <!-- <span class="btn-inner--icon">
              <i class="ni ni-check-bold icon-shape-success"></i>
            </span> -->
            <span class="btn-inner--text">
              {{ $t("USERS.ACTIVATE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_EDIT_USERS) && user.active == 1
            "
            @click="deactivateUser"
          >
            <!-- <span class="btn-inner--icon">
              <i class="ni ni-fat-remove icon-shape-danger"></i>
            </span> -->
            <span class="btn-inner--text">
              {{ $t("USERS.DEACTIVATE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="user.profile_image"
        class="all-infos-image"
        :src="`${user.profile_image}`"
        alt="logo"
      />

      <img v-else class="all-infos-image" src="/img/add-image.svg" alt="logo" />

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ user.firstname }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ user.lastname }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ user.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              user.phone_type
                ? $t(`COMMON.PHONE_TYPE_${user.phone_type}`) + ":"
                : null
            }}
            {{ user.phone }}
            {{
              user.phone_extension ? ` (ext: ${user.phone_extension})` : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span v-for="(line, index) in user.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCALE") }}</dt>
          <dd>
            {{
              user.locale
                ? $t(`COMMON.LOCALE_${user.locale.toUpperCase()}`)
                : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("USERS.ACTIVE") }}</dt>
          <dd>
            <icon-check :checked="!!user.active" />
          </dd>
        </dl>

        <dl class="row" v-if="$currentUser() && $currentUser().is_staff">
          <dt>{{ $t("USERS.IS_STAFF") }}</dt>
          <dd>
            <icon-check :checked="!!user.is_staff" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <dt>{{ $t("COMMON.ROLE") }}</dt>
          <dd>
            <router-link
              v-if="user.roles[0]"
              :to="$objectViewRoute(user.roles[0])"
            >
              {{ user.roles[0].name }}
            </router-link>
            <span v-else> {{ $t("COMMON.NONE") }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("USERS.RESTRICT_TO_LOCATIONS") }}</dt>
          <dd>
            <icon-check :checked="!!user.restrict_to_locations" />
          </dd>
        </dl>

        <dl class="row" v-if="user.restrict_to_locations">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="user.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="user.organization" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="user.reseller" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="user.created_at">
            {{ $formatDate(user.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="user.updated_at">
            {{ $formatDate(user.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4" style="display: none">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view
        style="display: none"
        :billingInformations="user"
      />
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "user-view-global",

  components: { IconCheck, BillingInformationsView },

  props: ["user"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    editUser() {
      this.$emit("onEditUser", this.user);
    },
    deleteUser() {
      this.$emit("onDeleteUser", this.user);
    },
    activateUser() {
      this.$emit("onActivateUser", this.user);
    },
    deactivateUser() {
      this.$emit("onDeactivateUser", this.user);
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
